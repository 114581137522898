// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { clienteScripts } from "../../../scripts/clientes"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiReferenciaLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Agregar un nuevo cliente (Emisor)</h2>
                <p>
                    Los clientes, también llamados Emisores, son los RFCs que emitirán facturas, puedes considerarlos como tus propios clientes, tal como el nombre indica.
                </p>
                <p>
                    <b>En CFDI 4.0, el SAT confirmará que el nombre, régimen y RFC del emisor coincidan</b>, por lo que es importante que los datos sean correctos, particularmente el nombre debe ser correcto.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/clients/create/<b><span className="error">:masterToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos de la solicitud
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>rfc: </b> RFC emisor del cliente
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>regimenFiscal: </b> La clave del régimen fiscal, según las establecidas por el SAT. <br />
                            <span className="small quote">Para conocer las claves de régimen fiscal, entra <Link to="/api/catalogo/catalogo-regimenfiscal">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>nombre: </b> Nombre o razón social del cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>lugarExpedicion: </b> Codigo postal donde está dado de alta el emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>isProduction: </b> Declara si el cliente es producción o para prueba <br />
                            <span className="small quote">Los clientes de prueba tienen que usar RFCs y certificados especiales, obtén los datos para prueba <Link to="../clientes-certificados">aquí</Link></span>
                        </p>
                    </li>
                </ul>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>rfc: </b> RFC emisor del cliente
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>regimenFiscal: </b> La clave del régimen fiscal, según las establecidas por el SAT. <br />
                            <span className="small quote">Para conocer las claves de régimen fiscal, entra <Link to="../../catalogo-regimen">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>nombre: </b> Nombre o razón social del cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>lugarExpedicion: </b> Codigo postal donde está dado de alta el emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>isProduction: </b> Declara si el cliente es producción o para prueba (true / false) <br />
                            <span className="small quote">Los clientes de prueba tienen que usar RFCs y certificados especiales, obtén los datos para prueba <Link to="../clientes-certificados">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken: </b> Esta es <span className="error">la llave de operación de tu cliente</span>, cada vez que quiera emitir, cancelar u obtener facturas, así como consultar los catálogos del SAT a través de nuestro API.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>masterToken: </b> Esta es <span className="error">la llave de acceso principal</span>, confirmando la clave usada para crear el cliente.
                        </p>
                    </li>
                </ul>
                <p>
                    Una vez creado el cliente, éste podrá ser editado pero no podrás cambiarlo de pruebas a producción; Sin embargo, <b>todo proceso que pasa satisfactoriamente el modo de pruebas, pasará en modo productivo de igual manera, por lo que en producción solo habrá que cambiar el authToken del usuario de producción por uno real.</b>
                </p>
                <h3>
                    Cliente Emisor de Prueba
                </h3>
                <p>
                    <b>Durante la etapa de prueba, deberás usar el cliente emisor de prueba proporcionado en esta página.</b> <br />
                    Cuando ya estés listo(a) para pasar a producción, deberás crear un cliente real y elegir el parámetro <b>isProduction</b> como <b>true</b>.
                </p>
                <SectionNavigate next={`/api/referencia/clientes-certificados`} />
            </div>
            <div className="side">
                <CodeBlock title="Registrar cliente" requests={clienteScripts.registro.requests} response={clienteScripts.registro.response} />
            </div>
        </div>
    </Layout>
)

export default ApiReferenciaLanding
